<template>
  <Layout>
    <div class="createCertifyzz">
      <modal-upload></modal-upload>
      <div class="container">
        <b-row class="spaceAddCertify">
          <b-col cols="12" md="3" class="pt-5" data-anima="top">
            <div class="navigate">
              <div class="title d-flex flex-column">
                {{ nameCourse }}
                <button
                  id="viewModuleLesson"
                  class="viewModuleLesson d-flex mt-2"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <IconSax class="ml-2" name="redo" type="outline" size="1rem" color="#c4c4c4" />
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <IconSax name="monitor" />
                  <p class="m-0 text ml-3">Dados do Curso</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto"  />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="book-square" />
                  <p class="m-0 text ml-3">Módulos e Aulas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="profile-2user" />
                  <p class="m-0 text ml-3">Turmas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="medal-star" />
                  <p class="m-0 text ml-3">Certificado</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <!-- <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/emails/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
              </div>
            </router-link> -->
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="bag-2" />
                  <p class="m-0 text ml-3">Vendas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>

              <div class="spaceBtn">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  @change="onStatusChange"
                  v-model="myDataVariable"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5 spaceTop" data-anima="top">
            <div class="containerWizzard">
              <div class="title d-flex justify-content-center w-100">
                Adicione o Certificado
                <IconSax class="ml-2" name="medal-star" size="1.7rem" />
              </div>
            </div>
            <div class="containerWizzardInputs">
              <div class="spaceInputs" v-if="midiacertifyurl === null">
                <b-form-group label="Modelo do Certificado" label-for="Modelo">
                  <p class="textDimenssion">{{ $t('certificates_component.supported_format', {extensions: "PNG/JPEG/JPG/WEBP"}) }}</p>
                  <p class="textDimenssion">Dimensão Recomendada: 1750x1240</p>
                  <div class="btn-openModal" @click="showModalUploadCertify">
                    <p class="text">
                      Clique e faça o Upload do Certificado do Curso
                    </p>
                  </div>
                </b-form-group>
              </div>
              <div class="spaceInputs" v-else>
                <div id="previewCertify" style="position: relative; z-index: 1">
                  <!-- create a div element for each selectedCertificateParams in a loop -->
                  <div
                    v-for="certifyParams in selectedCertificateParams"
                    :key="certifyParams.value"
                    class="certifyParams"
                  >
                    <VueDragResize
                      w="auto"
                      h="auto"
                      :parentW="imageW"
                      :parentH="imageH"
                      :x="left[certifyParams.value]"
                      :y="top[certifyParams.value]"
                      :isActive="true"
                      @clicked="() => setFieldSelect(certifyParams.value)"
                      v-on:dragging="
                        (rect) => resize(rect, certifyParams.value)
                      "
                      :isResizable="false"
                      :isDraggable="true"
                    >
                      <p
                        class="certifyText"
                        :data-value="certifyParams.value"
                        :style="`
                          cursor: grab;
                          color: ${colors[certifyParams.value]};
                          font-size: ${fontSizes[certifyParams.value]}px;
                          line-height: ${fontSizes[certifyParams.value]}px;
                          margin: 0;
                          font-family: ${fonts[certifyParams.value]};
                        `"
                      >
                        {{ certifyParams.name }}
                      </p>
                    </VueDragResize>
                  </div>

                  <!-- <div id="spaceText">
                    <VueDragResize
                      ref="VueDragResize"
                      w="auto"
                      h="auto"
                      :x="left"
                      :y="top"
                      :isActive="false"
                      v-on:resizing="resize"
                      v-on:dragging="resize"
                      :isResizable="false"
                      :isDraggable="true"
                    >
                      <p
                        class="nameText"
                        :style="
                          'cursor: grab; color:' +
                          color +
                          '; font-size:' +
                          fonteSize +
                          'px; line-height: normal; margin: 0; font-family:' +
                          fonteCertify +
                          ';'
                        "
                        id="nameText"
                      >
                        {{ nameCompleto }}
                      </p>
                    </VueDragResize> -->
                  <!-- <VueDragResize v-if="customInputs !== null" :w="600" :x="left2" :y="top2" :isActive="false" v-on:resizing="resize2" v-on:dragging="resize2" :isResizable="true" :isDraggable="true">
                        <p
                            class="nameText"
                            :style="
                            'cursor: grab; color:' +
                            customInputs.color +
                            '; font-size:' +
                            customInputs.fonteSize +
                            'px; font-family:'+
                            customInputs.fonteCertify+';'
                            "
                            id="nameText"
                        >
                            {{ customInputs.nameCompleto }}
                        </p>
                    </VueDragResize> -->
                  <!-- <p
                    class="nameText"
                    :style="
                      'position: absolute; margin-top: ' +
                      eixoY +
                      'px; margin-left: ' +
                      eixoX +
                      'px; color:' +
                      color +
                      '; font-size:' +
                      fonteSize +
                      'px; font-family:'+
                      fonteCertify+';'
                    "
                    id="nameText"
                  >
                    {{ nameCompleto }}
                  </p> -->
                  <!-- </div> -->
                  <img
                    :src="midiacertifyurl"
                    alt="midiacertify"
                    id="photoX"
                    @load="onMediaLoaded"
                  />
                </div>
                <br />
                <p class="textDimenssion">{{ $t('certificates_component.supported_format', {extensions: "PNG/JPEG/JPG/WEBP"}) }}</p>
                <p class="textDimenssion">Dimensão Recomendada: 1750x1240</p>
                <b-form-group label="Modelo do Certificado" label-for="Modelo">
                  <div class="btn-openModal" @click="showModalUploadCertify">
                    <p class="text">
                      Clique e faça o Upload do Certificado do Curso
                    </p>
                  </div>
                </b-form-group>
              </div>
              <div>
                <b-form-group
                  label="Adicionar campos"
                  label-for="name-module"
                >
                  <div>
                    <multiselect
                      placeholder="Selecione os campos personalizados"
                      :close-on-select="false"
                      @input="toggleCertParams"
                      :multiple="true"
                      :taggable="false"
                      :allowEmpty="false"
                      track-by="name"
                      label="name"
                      v-model="selectedCertificateParams"
                      :options="certificateParams"
                    >
                    </multiselect>
                  </div>
                </b-form-group>
                <b-form-group
                    label="Selecione a Porcentagem de Liberação de Certificado"
                    label-for="name"
                  >
                    <b-form-input
                      id="rangeCards"
                      v-model="rangeCertify"
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                    ></b-form-input>
                    <p class="textPorcentage">{{ rangeCertify }}%</p>
                  </b-form-group>
              </div>
              <div>
                <div class="spaceInputsConfig">
                  <!-- <b-form-group label="Seu nome completo(Exemplo)" label-for="name">
                    <b-form-input
                      v-model="nameCompleto"
                      placeholder="Seu Nome"
                    ></b-form-input>
                  </b-form-group> -->
                  <b-form-group label="Personalizar campos" label-for="update">
                    <b-form-select
                      placeholder="Personalizar campos"
                      :options="selectedCertificateParams"
                      value-field="value"
                      text-field="name"
                      v-model="fieldSelected"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="spaceInputsConfig">
                  <!-- <b-form-group label="Seu nome completo(Exemplo)" label-for="name">
                    <b-form-input
                      v-model="nameCompleto"
                      placeholder="Seu Nome"
                    ></b-form-input>
                  </b-form-group> -->
                  <b-form-group
                    label="Qual a fonte do Certificado"
                    label-for="fonte"
                  >
                    <b-form-select
                      v-model="selectedFont"
                      @input="(newValue) => setFieldFont(newValue)"
                      placeholder="Selecione uma Fonte"
                      :options="AllFontes"
                      value-field="id"
                      text-field="title"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div>
                  <b-form-group label="Tamanho da Fonte" label-for="name">
                    <b-form-input
                      id="rangeCards"
                      v-model="selectedFontSize"
                      @input="(newValue) => setFieldFontSize(newValue)"
                      type="range"
                      min="10"
                      max="100"
                      step="1"
                    ></b-form-input>
                    <p class="textPorcentage">{{ selectedFontSize }}px</p>
                  </b-form-group>
                  <b-form-group label="Cor da Fonte" label-for="name">
                    <v-swatches
                      v-model="selectedColor"
                      @input="(value) => setFieldColor(value)"
                      show-fallback
                      fallback-input-type="color"
                      inline
                    ></v-swatches>
                  </b-form-group>
                </div>
                <!-- <div>
                    <b-form-group label="Eixo X" label-for="name">
                      <b-form-input
                        id="rangeCards"
                        v-model="eixoX"
                        type="range"
                        min="0"
                        max="1500"
                        step="1"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Eixo Y" label-for="name">
                      <b-form-input
                        id="rangeCards"
                        v-model="eixoY"
                        type="range"
                        min="0"
                        max="1500"
                        step="1"
                      ></b-form-input>
                    </b-form-group>
                  </div> -->
              </div>

              <!-- <b-form-group label="Campos Personalizados" label-for="name-module">
                <div>
                    <multiselect :custom-label="customLabel" placeholder="Selecione um campo Personalizado!" selectedLabel="" deselectLabel="Pressione Enter" @input="selectItem" :close-on-select="true" selectLabel="Pressione Enter" track-by="value" :multiple="false" :taggable="false" v-model="customInputs" :options="optionsLessonCategory"></multiselect>
                </div>
            </b-form-group>
            <div v-if="customInputs !== null">
              <div class="spaceInputsConfig">
                <b-form-group label="Texto de Exemplo" label-for="name">
                  <b-form-input
                    v-model="customInputs.nameCompleto"
                    placeholder="Seu Texto"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Qual a fonte do Certificado"
                  label-for="fonte"
                >
                  <b-form-select
                    v-model="customInputs.fonteCertify"
                    placeholder="Selecione uma Fonte"
                    :options="AllFontes"
                    value-field="id"
                    text-field="title"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="spaceInputsConfig">
                <b-form-group label="Tamanho da Fonte" label-for="name">
                  <b-form-input
                    id="rangeCards"
                    v-model="customInputs.fonteSize"
                    type="range"
                    min="10"
                    max="100"
                    step="1"
                  ></b-form-input>
                  <p class="textPorcentage">{{ parseInt(customInputs.fonteSize) }}px</p>
                </b-form-group>

                <b-form-group label="Cor da Fonte" label-for="name">
                  <v-swatches
                    v-model="customInputs.color"
                    show-fallback
                    fallback-input-type="color"
                    inline
                  ></v-swatches>
                </b-form-group>
              </div>
            </div> -->

              <div class="spaceInputs" v-if="midiacertifyurl !== null">
                <button
                  class="btn-criar mb-3"
                  style="
                    background: #ff0c37 !important;
                    border: none !important;
                  "
                  @click.prevent="deleteCertificate()"
                >
                  Deletar Certificado
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.0979614 9.01421C0.0979614 9.44138 0.59902 9.67184 0.923357 9.39384L5.4884 5.48095C5.7212 5.2814 5.7212 4.92124 5.4884 4.72169L0.923357 0.808801C0.59902 0.530798 0.0979614 0.761252 0.0979614 1.18843L0.0979614 9.01421Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button
                  class="btn-criar mb-3"
                  @click.prevent="saveCertificate('test')"
                >
                  Salvar e Baixar como Teste
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.0979614 9.01421C0.0979614 9.44138 0.59902 9.67184 0.923357 9.39384L5.4884 5.48095C5.7212 5.2814 5.7212 4.92124 5.4884 4.72169L0.923357 0.808801C0.59902 0.530798 0.0979614 0.761252 0.0979614 1.18843L0.0979614 9.01421Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button class="btn-criar" @click.prevent="saveCertificate()">
                  Salvar Certificado
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.0979614 9.01421C0.0979614 9.44138 0.59902 9.67184 0.923357 9.39384L5.4884 5.48095C5.7212 5.2814 5.7212 4.92124 5.4884 4.72169L0.923357 0.808801C0.59902 0.530798 0.0979614 0.761252 0.0979614 1.18843L0.0979614 9.01421Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import fp from "@/utils/fp";
import Layout from "@/layout/Layout.vue";
import ModalUpload from "@/components/ModalUpload.vue";
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import VueDragResize from "vue-drag-resize";
import notify from "@/services/libs/notificacao";
import Multiselect from "vue-multiselect";
import CustomFieldsService from "@/services/resources/CustomFieldsService";
const service = CustomFieldsService.build();
import axios from "axios";
import Cookies from "js-cookie";
import { getExtensionFileFromURL } from "@/helpers";

export default {
  components: {
    Layout,
    ModalUpload,
    VSwatches,
    VueDragResize,
    Multiselect,
  },
  data() {
    return {
      courseSelect: 1,
      AllCourse: "",
      data: "",
      nameCourse: "",
      myDataVariable: true,
      cursoAtual: "",
      fieldSelected: null,
      existParans: false,
      midiacertifyurl: null,
      midiacertify: null,
      certificateParams: [
        { name: "Nome do Aluno", value: "studentName" },
        { name: "Documento do Aluno", value: "studentDocument" },
        { name: "Nome do Curso", value: "courseName" },
        { name: "Data de Conclusão", value: "courseFinishedDate" },
      ],
      selectedCertificateParams: [],
      AllFontes: [
        "Anton",
        "Architects Daughter",
        "Cabin Condensed",
        "Caveat",
        "Dancing Script",
        "Gloria Hallelujah",
        "Great Vibes",
        "Lobster",
        "Noto Sans JP",
        "Open Sans Condensed",
        "Oswald",
        "Pacifico",
        "Roboto",
        "Roboto Mono",
      ],
      // selectedFont: "Anton",
      // selectedFontSize: 28,
      // selectedColor: "#000",
      top: { },
      left: { },
      fonts: {},
      fontSizes: {},
      colors: {},
      media_meta: null,
      width: [0],
      height: [0],
      imageW: 500,
      imageH: 500,
      eixoX: 0,
      eixoY: 0,
      state: "mouting",
      // top2: 400,
      // left2: 0,
      // eixoX2: 0,
      // eixoY2: 0,
      certifyId: null,
      rangeCertify: 75,
      customInputs: null,
      optionsLessonCategory: [],
      centerName: false,
      notexist: null,
    };
  },
  computed: {
    isSelected() {
      return this.selectedCertificateParams.find(it => it.value == this.fieldSelected)
    },
    selectedTop: {
      get() {
        return this.top[this.fieldSelected]
      },
      set(newValue) {
        if (this.isSelected) {
          this.top = fp.set(this.fieldSelected, newValue, this.top)
        } else {
          this.top = fp.omit(this.fieldSelected, this.top)
        }
      }
    },
    selectedLeft: {
      get() {
        return this.left[this.fieldSelected]
      },
      set(newValue) {
        if (this.isSelected) {
          this.left = fp.set(this.fieldSelected, newValue, this.left)
        } else {
          this.left = fp.omit(this.fieldSelected, this.left)
        }
      }
    },
    selectedFont: {
      get() {
        return this.fonts[this.fieldSelected] || "Anton"
      },
      set(newValue) {
        if (this.isSelected) {
          this.fonts = fp.set(this.fieldSelected, newValue, this.fonts)
        } else {
          this.fonts = fp.omit(this.fieldSelected, this.fonts)
        }
      }
    },
    selectedFontSize: {
      get() {
        return this.fontSizes[this.fieldSelected] || "33"
      },
      set(newValue) {
        if (this.isSelected) {
          this.fontSizes = fp.set(this.fieldSelected, newValue, this.fontSizes)
        } else {
          this.fontSizes = fp.omit(this.fieldSelected, this.fontSizes)
        }
      }
    },
    selectedColor: {
      get() {
        return this.colors[this.fieldSelected] || "#000"
      },
      set(newValue) {
        if (this.isSelected) {
          this.colors = fp.set(this.fieldSelected, newValue, this.colors)
        } else {
          this.colors = fp.omit(this.fieldSelected, this.colors)
        }
      }
    }
  },
  methods: {
    toggleCertParams(selectedParams) {
      this.selectedCertificateParams = selectedParams
      fp.map(param => {
        this.fieldSelected = param.value
        this.selectedColor = this.selectedColor
        this.selectedFont = this.selectedFont
        this.selectedFontSize = this.selectedFontSize
        this.selectedTop = this.selectedTop
        this.selectedLeft = this.selectedLeft
      }, this.certificateParams)
    },
    deleteCertificate() {
      
      if (this.notexist === true) {
        this.midiacertifyurl = null;
        notify("sucesso", "Certificado removido com Sucesso!");
      } else {
        serviceCourse
          .destroy(this.cursoAtual + "/certificate")
          .then(() => {
            //console.log("delete certify", resp);
            this.getDataParams();
            this.getCustoms();
            this.midiacertifyurl = null;
            notify("sucesso", "Certificado deletado com Sucesso!");
          })
          .catch(() => {
            //console.log(err);
            notify("erro", "Erro ao Deletar Certificado!");
            this.midiacertifyurl = null;
          });
      }
    },
    // customLabel: function ({ text }) {
    //   return `${text}`;
    // },
    getCustoms() {
      service
        .search()
        .then((resp) => {
          //console.log("get-fields", resp);
          resp.forEach((item) => {
            this.optionsLessonCategory.push({
              value: item.id,
              text: item.name,
            });
          });
        })
        .catch(() => {
          //console.log(err);
        });
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        //console.log("get modules", resp);
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },
    onStatusChange() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      this.$root.$emit("loadOn");
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then(() => {
          this.$root.$emit("loadOff");
          //console.log("update course status", resp);
          this.getCourse();
        })
        .catch(() => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    async saveCertificate(test) {
      const allowedExtensions = ['png', 'jpeg', 'jpg', 'webp'];
      const extension = getExtensionFileFromURL(this.midiacertifyurl);

      if(!allowedExtensions.includes(extension)) {
        notify("erro", this.$t('certificates_component.unsupported_format', { extensions: allowedExtensions.join('/').toUpperCase() })); 
        return;
      }
  
      const photoX = document.getElementById("photoX");
      const widthProportion = photoX.naturalWidth / photoX.clientWidth;
      const heightProportion = photoX.naturalHeight / photoX.clientHeight;

      const axisX = fp.mapValues(
        (x) => parseInt(x * widthProportion, 10),
        this.left
      );

      const axisY = fp.mapValues(
        (y) => parseInt(y * heightProportion, 10),
        this.top
      );

      const sizes = fp.mapValues((size) => {
        return parseInt(size * widthProportion, 10);
      }, this.fontSizes);

      // var fontSize = size * somaWidth;

      // resulty = resulty - document.querySelector(".certifyText").offsetHeight;
      const notHasCertificate =
        this.certifyId === null || this.certifyId === undefined;

      let data = {
        id:
          this.cursoAtual +
          `${notHasCertificate ? "/certificate" : "/certificate/update"}`,
        position_x: axisX,
        position_y: axisY,
        font_size: sizes,
        font_color: this.colors,
        font: this.fonts,
        media_id: this.midiacertify,
        percentage: parseInt(this.rangeCertify, 10),
      };

      data.center_horizontal = "off";

      serviceCourse
        .postID(data)
        .then(() => {
          //console.log("create certify", resp);
          notify("sucesso", "Certificado Salvo com Sucesso!");
          if (test) {
            this.testCertificate();
          }
          this.getCertificate();
        })
        .catch(() => {
          notify("erro", "Erro ao criar Certificado!");
          //console.log(err);
        });
    },
    testCertificate() {
      axios({
        url:
          process.env.VUE_APP_API_HOST +
          `/course/${this.cursoAtual}/certificate/test`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      }).then((response) => {
        const type = response.headers["content-type"];
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type })
        );

        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Certificado-${this.nameCourse}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    resize(newRect, index) {
      if (this.state != "loaded" || newRect.top == 0) {
        return;
      }
      this.width = fp.set(index, newRect.width, this.width);
      this.height = fp.set(index, newRect.height, this.height);
      this.top = fp.set(index, newRect.top, this.top);
      this.left = fp.set(index, newRect.left, this.left);
    },
    setFieldSelect(selectedField) {
      this.fieldSelected = selectedField;
    },
    setFieldFont(font) {
      this.selectedFont = font;
    },
    setFieldColor(color) {
      this.selectedColor = color;
    },
    setFieldFontSize(size) {
      this.selectedFontSize = parseInt(size, 10);
    },
    fieldDeactivate() {
      this.fieldSelected = null;
    },
    getCourse() {
      let data = {
        id: this.cursoAtual,
      };
      serviceCourse
        .read(data)
        .then((resp) => {
          //console.log("get course", resp);
          this.AllCourse = resp.data;
          this.nameCourse = resp.title;
        })
        .catch(() => {
          //console.log(err);
        });
    },
    async getCertificate() {
      const resp = await serviceCourse.read(this.cursoAtual + "/certificate");
      try {
        if (JSON.stringify(resp) !== "{}") {
          this.notexist = false;
          this.certifyId = resp.id;
          this.midiacertify = resp.media.id;
          this.midiacertifyurl = resp.media.cdn_url;
          this.rangeCertify = resp.percentage;
          this.media_meta = resp.media.media_meta;
        } else {
          this.notexist = true;
          this.certifyId = null;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getDataParams() {
      const params = this.$route.params.curso;
      //console.log(params);
      if (params === null || params === undefined || params === "") {
        this.$router.push("/course");
      } else {
        this.cursoAtual = params;
        this.existParans = false;
        this.getCourse();
        this.getCertificate("mounted");
      }
    },
    showModalUploadCertify() {
      this.$root.$emit("certify");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    loadFields() {
      const photoX = document.getElementById("photoX");
      const widthProportion = photoX?.clientWidth / photoX.naturalWidth;
      const heightProportion = photoX?.clientHeight / photoX.naturalHeight;
      let { media_meta } = this;

      fp.forEach((meta) => {
        switch (meta.key) {
          case "position_x": {
            const resultX = parseInt(meta.value, 10) * widthProportion;
            this.left = fp.set(meta.pivot, parseInt(resultX, 10), this.left);
            break;
          }
          case "position_y": {
            const resultY = parseInt(meta.value, 10) * heightProportion;
            this.top = fp.set(meta.pivot, parseInt(resultY, 10), this.top);
            break;
          }
          case "font_size": {
            const resultF = meta.value * widthProportion;
            this.fontSizes = fp.set(meta.pivot, resultF, this.fontSizes);
            break;
          }
          case "font_color": {
            this.colors = fp.set(meta.pivot, meta.value, this.colors);
            break;
          }
          case "font": {
            this.fonts = fp.set(meta.pivot, meta.value, this.fonts);
            break;
          }
          case "center_horizontal": {
            this.centerName = meta.value === "on";
            break;
          }
        }
      }, media_meta);

      this.selectedCertificateParams = fp.compose(
        fp.uniqBy((it) => it.value ),
        fp.compact,
        fp.map((key) => {
          return this.certificateParams.find(
            (element) => element.value === key
          );
        }),
        fp.keys,
        fp.groupBy("pivot")
      )(media_meta);

      this.state = "loaded";
    },
    onMediaLoaded() {
      const previewCertify = document.getElementById("previewCertify");
      this.loadFields();
      setTimeout(() => {
        this.imageW = previewCertify.clientWidth;
        this.imageH = previewCertify.clientHeight;
      }, 200);
    },
  },
  mounted() {
    this.getDataParams();
    this.getCustoms();
    this.$root.$on("midiacertify", (data) => {
      this.midiacertify = parseInt(data.id);
      this.midiacertifyurl = data.url;
    });
  },
};
</script>
<style lang="scss">
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 30px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 30px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 30px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 30px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 30px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir="rtl"] .multiselect {
  text-align: right;
}
[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir="rtl"] .multiselect__content {
  text-align: right;
}
[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
.createCertifyzz {
  .textPorcentage {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin-top: -10px;
  }
  .spaceInputsConfig {
    select {
      background-color: var(--backgroundcolor) !important;
    }
    margin-top: 20px;
    .multiselect__tags {
      padding: 16px 40px 0 8px !important;
      height: 55px !important;
    }
    .multiselect__single {
      padding-left: 5px;
      margin-bottom: 8px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
    input,
    select,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
    }
    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor2);
    }
  }
  .removeLink {
    text-decoration: none;
  }
  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor);
      word-break: break-word;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      // justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      p {
        font-family: Inter;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #333333;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }

  .pt-5.spaceTop.col-md-12.col-12 {
    margin-top: 3em;
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }

        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceAddCertify {
    margin-left: 83px !important;
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        .titleModule {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 32px;
        margin-bottom: 20px;
      }
      .spaceInputs {
        select {
          background-color: var(--backgroundcolor) !important;
        }
        margin-bottom: 30px;
        .textInfo {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #81858e;
          margin-top: 5px;
        }
        .btn-criar {
          background: var(--maincolor);
          border: 1px solid var(--maincolor);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          height: 55px;
        }
        .btn-openModal {
          background: var(--maincolortrans);
          border: 2px dashed var(--maincolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          cursor: pointer;
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--maincolor);
            display: flex;
            justify-content: center;
            margin-top: 17px;
          }
        }
        .gerenciarCat {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          position: absolute;
          right: 25em;
          cursor: pointer;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        input,
        textarea {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          overflow: hidden !important;
          resize: none !important;
        }
        input:hover,
        select:hover,
        textarea:hover,
        input:focus,
        select:focus,
        textarea:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor2);
        }
      }
    }
    .containerWizzard {
      display: flex;
      text-align: center;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        align-items: center;
        color: var(--fontcolor);
      }
    }
  }
  .containerx {
    width: 98%;
  }
}
</style>
